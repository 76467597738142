<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="info" v-bind="attrs" v-on="on">
              <v-icon>mdi-swap-vertical</v-icon>
            </v-btn>
          </template>
          <span>Domain Migrate</span>
        </v-tooltip>
      </div>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">Domain migrate</span>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <ServerSelect
                ref="OldServerSelectRef"
                :label="'Old server *'"
                :changeValue="(value) => (old_server = value)"
              />
            </v-col>
            <v-col cols="12">
              <ServerSelect
                ref="NewServerSelectRef"
                :label="'New server *'"
                :changeValue="(value) => (new_server = value)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          color="blue darken-1"
          text
          @click="closeModal"
        >
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          type="button"
          @click="onReplaceDomains"
          :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { requestStatus } from "@/services/services";
import { domainsMigrate } from "@/store/modules/domains/api";
import ServerSelect from "./ServerSelect.vue";
export default {
  props: ["updadeList"],
  components: {
    ServerSelect,
  },
  data() {
    return {
      dialog: false,
      new_server: "",
      old_server: "",
      loading: false,
    };
  },
  methods: {
    onReplaceDomains() {
      if (this.old_server && this.new_server) {
        this.loading = true;
        domainsMigrate({
          old_server: +this.old_server.id,
          new_server: +this.new_server.id,
        })
          .then((response) => {
            if (response.status === requestStatus.success) {
              this.$toaster.success("Domains have been changed");
              this.updadeList();
              this.closeModal();
            } else {
              this.$toaster.error(
                response.messages || "Oops, something went wrong."
              );
            }
          })
          .finally(() => (this.loading = false));
      } else {
        this.$toaster.error("Indicates required field.");
      }
    },

    closeModal() {
      this.old_server = "";
      this.new_server = "";
      this.$refs.OldServerSelectRef.clear();
      this.$refs.NewServerSelectRef.clear();
      this.dialog = false;
    },
  },
};
</script>
