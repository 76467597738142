<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="domain"
          label="Domain *"
          required
          hide-details
          @input="changeField({ field: 'domain', value: domain })"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="server"
          :items="serversList"
          :loading="isLoadingServerss"
          :search-input.sync="searchServer"
          @focus="() => onLoadServer()"
          placeholder="Search"
          item-text="name"
          item-value="id"
          label="Server *"
          required
          return-object
          hide-details
          @change="
            changeField({ field: 'server', value: server ? server.id : null })
          "
        >
          <template #item="{ item }">
            <div>{{ item.name }} / {{ item.ip }}</div>
          </template></v-autocomplete
        >
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="registrator"
          :items="registratorsList"
          :loading="isLoadingregistrators"
          :search-input.sync="searchRegistrator"
          @focus="() => onLoadRegistrators()"
          placeholder="Search"
          item-text="name"
          item-value="id"
          label="Registrator *"
          required
          return-object
          hide-details
          @change="
            changeField({
              field: 'registrator',
              value: registrator ? registrator.id : null,
            })
          "
        ></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { requestStatus } from "@/services/services";

import { loadServers, loadRegistrators } from "@/store/modules/domains/api";
export default {
  props: ["changeField", "isOpen"],

  data() {
    return {
      registration_date: null,
      expiration_date: null,
      domain: "",
      registrator: "",
      registratorsList: [],
      isLoadingregistrators: false,
      searchRegistrator: "",
      server: "",
      serversList: [],
      isLoadingServerss: false,
      searchServer: "",
    };
  },
  watch: {
    isOpen(isOpen) {
      if (!isOpen) {
        this.domain = "";
        this.server = "";
        this.registrator = "";
        this.searchRegistrator = "";
        this.searchServer = "";
      }
    },
    searchServer() {
      this.onWaitChangeDomain();
    },
    searchRegistrator() {
      this.onWaitChangeRegistrator();
    },
  },
  methods: {
    onWaitChangeRegistrator() {
      if (
        this.searchRegistrator &&
        (this.searchRegistrator.length >= 2 || this.searchRegistrator === "")
      ) {
        this.onLoadRegistrators(this.searchRegistrator);
      }
    },
    onLoadRegistrators(search) {
      loadRegistrators(`search=${search ? search : ""}`).then((response) => {
        if (response.status === requestStatus.success) {
          this.registratorsList = response.response.data.results;
        }
      });
    },
    onWaitChangeDomain() {
      if (
        this.searchServer &&
        (this.searchServer.length >= 2 || this.searchServer === "")
      ) {
        if (this.searchServer != `${this.server.name}/${this.server.ip}`)
          this.onLoadServer(this.searchServer);
      }
    },
    onLoadServer(search) {
      loadServers(`search=${search ? search : ""}`).then((response) => {
        if (response.status === requestStatus.success) {
          this.serversList = response.response.data.results;
        }
      });
    },
  },
};
</script>

<style></style>
