<template>
  <v-card>
    <v-card-title>
      <div
        class="d-flex justify-content-between w-100 align-center domain-filters"
      >
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              label="Search..."
              v-model="searchDomain"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              :items="isBannedFilter"
              label="Is banned"
              :value="is_banned ? is_banned : null"
              item-text="label"
              item-value="value"
              @change="(is_banned) => changeFilter({ is_banned })"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-select
              :items="isActiveFilter"
              label="Is active"
              :value="is_active ? is_active : null"
              item-text="label"
              item-value="value"
              @change="(is_active) => changeFilter({ is_active })"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <v-switch
              :inputValue="
                is_expiring_within_a_week ? is_expiring_within_a_week : null
              "
              label="Expiring within a week"
              color="primary"
              hide-details
              @change="
                (is_expiring_within_a_week) =>
                  changeFilter({ is_expiring_within_a_week })
              "
            ></v-switch>
          </v-col>
        </v-row>
        <div class="d-flex align-center">
          <v-btn color="primary" class="ma-2" @click="toggleOpenCreateDomain">
            Create Domain
          </v-btn>
          <DomainReplace :updadeList="() => onLoadData()" />
          <DomainMigrate :updadeList="() => onLoadData()" />
          <ChangeDomainsServer :updadeList="() => onLoadData()" />
        </div>
      </div>
    </v-card-title>
    <v-card-text :key="keyTable">
      <v-data-table
        :headers="headers"
        :items="list"
        hide-default-footer
        :loading="loading"
        :itemsPerPage="+pageFilter.limit"
        disable-sort
        dense
        :item-class="rowClass"
      >
        <template v-slot:header.name="{ header }">
          {{ header.text.toUpperCase() }}
        </template>

        <template
          v-for="header in headers.filter((header) =>
            header.hasOwnProperty('formatter')
          )"
          v-slot:[`item.${header.value}`]="{ header, value }"
        >
          {{ header.formatter(value) }}
        </template>

        <template v-slot:item.is_active="{ item }">
          <div class="d-inline-flex">
            <v-switch
              class="ma-0 pa-0"
              hide-details
              :inputValue="item.is_active"
              :loading="
                isEditing && status.editing_domain === item.id
                  ? 'warning'
                  : false
              "
              @change="(e) => toggleSwitcher(e, item)"
              :disabled="isEditing"
            >
            </v-switch>
          </div>
        </template>

        <template v-slot:item.is_banned="{ item }">
          <span>
            <v-icon v-if="item.is_banned" color="green" small>mdi-check</v-icon>

            <v-icon v-else color="red" small>mdi-close</v-icon>
          </span>
        </template>
        <template v-slot:item.server="{ item }">
          <span v-if="item.server">
            {{ item.server.name }} / {{ item.server.ip }}
          </span>
        </template>
        <template v-slot:item.registrator="{ item }">
          <span v-if="item.registrator">
            {{ item.registrator.name }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openChangeDomainServerModal(item)"
                icon
                color="info"
                v-bind="attrs"
                v-on="on"
                x-small
              >
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <span>Change domain server</span>
          </v-tooltip>
        </template>

        <template v-slot:footer>
          <pagination :count="count" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {
  LOAD_LIST,
  EDIT_DOMAINS,
  CLEAR_LIST,
} from "../../../store/modules/domains/types";
import {
  renderDate,
  requestStatus,
  buildFilterString,
} from "@/services/services";
import Pagination from "@/components/Pagination";
import { CHANGE_FILTER } from "../../../store/modules/page/types";
import { toBooleanValue } from "../../../services/services";
import DomainReplace from "./DomainReplace.vue";
import DomainMigrate from "./DomainMigrate.vue";
import ChangeDomainsServer from "./ChangeDomainsServer.vue";
export default {
  name: "DomainsTable",
  components: { Pagination, DomainReplace, DomainMigrate, ChangeDomainsServer },
  props: { toggleOpenCreateDomain: Function },
  data: () => ({
    headers: [
      {
        text: "Domain",
        align: "start",
        value: "domain",
      },
      {
        text: "Created at",
        value: "created_at",
        formatter: (x) => (x ? renderDate(x) : null),
      },

      { text: "Is Active", value: "is_active" },
      { text: "Is Banned", value: "is_banned" },
      { text: "Server", value: "server" },
      { text: "Registrator", value: "registrator" },
      // {
      //   text: "Registration at",
      //   value: "registration_date",
      //   formatter: (x) => (x ? renderDate(x) : null),
      // },
      {
        text: "Expiration at",
        value: "expiration_date",
        formatter: (x) => (x ? renderDate(x) : null),
      },
      { text: "", value: "actions" },
    ],
    loading: false,
    isActiveFilter: [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "true",
      },
      {
        label: "Inactive",
        value: "false",
      },
    ],
    isBannedFilter: [
      {
        label: "All",
        value: null,
      },
      {
        label: "Banned",
        value: "true",
      },
      {
        label: "Not Banned",
        value: "false",
      },
    ],
    isEditing: false,
    keyTable: "",
    searchDomain: "",
  }),
  computed: {
    ...mapState({
      errors: (state) => state.domains.errors,
      list: (state) => state.domains.list,
      count: (state) => +state.domains.count,
      status: (state) => state.domains.status,
      pageFilter: (state) => state.page.filter,
      is_active: (state) => state.page.filter.is_active,
      is_expiring_within_a_week: (state) =>
        state.page.filter.is_expiring_within_a_week,
      is_banned: (state) => state.page.filter.is_banned,
      search: (state) => state.page.filter.search,
    }),
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  methods: {
    ...mapActions({
      onLoadList: `domains/${LOAD_LIST}`,
      onEditDomain: `domains/${EDIT_DOMAINS}`,
      clearList: `domains/${CLEAR_LIST}`,
    }),
    ...mapMutations({
      changeFilter: `page/${CHANGE_FILTER}`,
    }),
    rowClass(item) {
      return item.is_expiring_within_a_week ? "is_expiring_within_a_week" : "";
    },
    openChangeDomainServerModal(item) {
      window.getApp.$emit("OPEN_CHANGE_DOMAIN_SERVER_MODAL", item);
    },
    onLoadData(pageFilter) {
      this.clearList();
      this.onLoadList(
        `${buildFilterString(
          pageFilter ? pageFilter : this.pageFilter
        )}&ordering=-id`
      );
    },

    toggleSwitcher(newValue, item) {
      this.onEditDomain({ id: item.id, data: { is_active: newValue } });
    },
    onWaitChangeDomain() {
      if (
        (this.searchDomain || this.searchDomain === "") &&
        (this.searchDomain.length >= 2 || this.searchDomain === "")
      ) {
        this.changeFilter({ search: this.searchDomain });
      }
    },
  },
  watch: {
    status(newValue) {
      this.loading = newValue.load_domains === requestStatus.loading;
      this.isEditing = newValue.edit_domains === requestStatus.loading;

      if (
        newValue.change_field === "create_domains" &&
        newValue.create_domains === requestStatus.success
      ) {
        this.onLoadData(this.pageFilter);
        this.$toaster.success("Success create domains");
      }
      if (
        newValue.change_field === "edit_domains" &&
        newValue.edit_domains === requestStatus.success
      ) {
        this.onLoadData(this.pageFilter);
        this.$toaster.success("Success toggle domains");
      }
      if (
        newValue.change_field === "edit_domains" &&
        newValue.edit_domains === requestStatus.error
      ) {
        this.keyTable = `${new Date()}`;
        this.$toaster.error("Error toggle domains");
      }
    },
    searchDomain() {
      this.onWaitChangeDomain();
    },
    pageFilter(newValue) {
      this.onLoadData(newValue);
    },
  },
  created() {
    this.searchDomain = this.search;
    if (!this.search) this.onLoadData(this.pageFilter);
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  .domain-filters {
    display: flex;
    flex-direction: column;
  }
}

.is_expiring_within_a_week {
  background: #ffe4e4;
}
</style>
