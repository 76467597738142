<template>
  <v-dialog :value="isOpen" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Create Domain</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div>
            <CreateDomainItem
              :isOpen="isOpen"
              :changeField="(obj) => changeDomainFiled(obj)"
            />
            <v-row class="mt-4">
              <v-col cols="12">
                <div class="d-flex">
                  <div class="mr-4 custom-date-picker" style="width: 50%">
                    <!-- <span>Registration date</span> -->
                    <date-picker
                      style="width: 100%"
                      v-model="registration_date"
                      type="date"
                      placeholder="Registration date"
                      value-type="format"
                      format="YYYY-MM-DD"
                      class="mb-2"
                    ></date-picker>
                  </div>
                  <div style="width: 50%" class="custom-date-picker">
                    <!-- <span>Expiration date</span> -->
                    <date-picker
                      style="width: 100%"
                      v-model="expiration_date"
                      type="date"
                      placeholder="Expiration date"
                      value-type="format"
                      format="YYYY-MM-DD"
                      class="mb-2"
                    ></date-picker>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="is_loading"
          color="blue darken-1"
          text
          @click="toggleOpenModal"
        >
          Close
        </v-btn>
        <v-btn
          :loading="is_loading"
          color="blue darken-1"
          text
          type="button"
          @click="onCreateClick"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CreateDomainItem from "./CreateDomainItem.vue";
import { requestStatus } from "@/services/services";
import { createDomain } from "@/store/modules/domains/api";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  name: "Create",
  components: {
    CreateDomainItem,
    DatePicker,
  },
  props: {
    toggleOpenModal: Function,
    isOpen: Boolean,
  },
  data: () => ({
    domain: {
      domain: "",
      server: "",
      registrator: "",
    },
    registration_date: null,
    expiration_date: null,
    is_loading: false,
  }),
  methods: {
    changeDomainFiled(obj) {
      this.domain[obj.field] = obj.value;
    },
    onCreateClick() {
      if (this.domain.domain && this.domain.server && this.domain.registrator) {
        this.is_loading = true;
        let data = {
          domain: this.domain.domain,
          server: +this.domain.server,
          registrator: +this.domain.registrator,
        };

        if (this.registration_date)
          data["registration_date"] = this.registration_date;
        if (this.expiration_date)
          data["expiration_date"] = this.expiration_date;

        createDomain(data)
          .then((response) => {
            console.log(response);
            if (response.status === requestStatus.success) {
              this.$toaster.success("Domains have been created");
              this.toggleOpenModal();
            } else {
              this.$toaster.error(
                response.messages || "Oops, something went wrong."
              );
            }
          })
          .finally(() => (this.is_loading = false));
      } else {
        this.$toaster.error("Fill in all fields");
      }
    },
  },
};
</script>

<style lang="scss">
.custom-date-picker {
  .mx-input:hover,
  .mx-input:focus {
    border-color: #1976d2 !important;
  }
}
</style>
