<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="info" v-bind="attrs" v-on="on">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <span>Change domain server</span>
        </v-tooltip>
      </div>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">Change domains server</span>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                chips
                v-model="select_domains"
                :items="domainsList"
                :loading="isLoadingDomains"
                :search-input.sync="searchDomain"
                @focus="() => loadDomainsList()"
                placeholder="Search"
                item-text="domain"
                item-value="id"
                label="Domains *"
                required
                return-object
                multiple
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <ServerSelect
                ref="NewServerSelectRef"
                :label="'New server *'"
                :changeValue="(value) => (new_server = value)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          color="blue darken-1"
          text
          @click="closeModal"
        >
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          type="button"
          @click="onReplaceDomains"
          :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { requestStatus } from "@/services/services";
import { domainsMigrate } from "@/store/modules/domains/api";
import ServerSelect from "./ServerSelect.vue";
import _ from "lodash";
import { loadList } from "../../../store/modules/domains/api";
export default {
  props: ["updadeList"],
  components: {
    ServerSelect,
  },
  data() {
    return {
      dialog: false,
      new_server: "",
      loading: false,
      select_domains: [],
      isLoadingDomains: false,
      searchDomain: "",
      domains: [],
    };
  },
  watch: {
    searchDomain() {
      this.onWaitChangeDomain();
    },
  },
  computed: {
    domainsList() {
      return this.select_domains
        ? [...this.domains, ...this.select_domains]
        : this.domains;
    },
  },
  mounted() {
    window.getApp.$on("OPEN_CHANGE_DOMAIN_SERVER_MODAL", (domain) => {
      this.domains.push(domain);
      this.select_domains.push(domain);
      this.dialog = true;
    });
  },
  methods: {
    onWaitChangeDomain() {
      if (
        (this.searchDomain || this.searchDomain === "") &&
        (this.searchDomain.length >= 2 || this.searchDomain === "")
      ) {
        this.loadDomainsList(this.searchDomain);
      }
    },
    loadDomainsList(search) {
      loadList(
        `is_active=true&is_banned=false&ordering=-id&search=${
          search ? search : ""
        }`
      ).then((response) => {
        this.domains = response.response.data.results;
      });
    },
    onReplaceDomains() {
      if (this.new_server && this.select_domains.length) {
        this.loading = true;
        let data = {
          domains: this.select_domains.map((item) => item.id),
          new_server: +this.new_server.id,
        };
        domainsMigrate(data)
          .then((response) => {
            if (response.status === requestStatus.success) {
              this.$toaster.success("Domains have been changed");
              this.updadeList();
              this.closeModal();
            } else {
              this.$toaster.error(
                response.messages || "Oops, something went wrong."
              );
            }
          })
          .finally(() => (this.loading = false));
      } else {
        this.$toaster.error("Indicates required field.");
      }
    },

    closeModal() {
      this.new_server = "";
      this.$refs.NewServerSelectRef.clear();
      this.domains = [];
      this.select_domains = [];
      this.dialog = false;
    },
  },
};
</script>
